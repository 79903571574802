import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
    const toggleSidebar = () => {
        const body = document.body;
        let classNameToAddOrRemove = 'toggle-sidebar';
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            
            if (body.classList.contains(classNameToAddOrRemove)) {
                // If it's present, remove the class
                body.classList.remove(classNameToAddOrRemove);
            } else {
                // If it's not present, add the class
                body.classList.add(classNameToAddOrRemove);
            }
          }
    }
    return (

        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className="nav-link " to="/admin/dashboard" onClick={toggleSidebar}>
                        <i className="bi bi-grid"></i>
                        <span style={{'color':'#012970'}}>Dashboard</span>
                    </Link>
                </li>
              

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav1" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Profile</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav1" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/update-profile" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Edit Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/update-password" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Change Password</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav2" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Company</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav2" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/company" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add company</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/company-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Company List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav3" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Company Banners</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav3" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/company-banner" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Company Banner</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/company-banner-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Company Banner List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav4" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Company Clients</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav4" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/company-client" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Company Client</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/company-client-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Company Client List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav5" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Company Service</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav5" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/company-service" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Company Service</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/company-service-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Company Service List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav6" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Company Faq</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav6" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                       
                        <li>
                            <Link to="/admin/company-faq-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Company Faq List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav7" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Categories</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav7" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/categories" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Category List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-category" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Category</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav8" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Tags</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav8" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/tags" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Tags List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-tag" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Tags</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav10" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Articles</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav10" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/articles" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Article List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-article" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create Article</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav9" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>User Management</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav9" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/users" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Users List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-user" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add User</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav11" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide" ></i><span>Page Management</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav11" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/pages" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Page List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-page" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create Page</span>
                            </Link>
                        </li>




                    </ul>
                </li>
               
          
               




            </ul>

        </aside>

    )
}

export default Sidebar