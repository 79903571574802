import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from '../layout/SidebarLayout';
import UserLayout from '../layout/UserLayout';
const Loader = (Component) => (props) =>
(
    <Suspense >
        <Component {...props} />
    </Suspense>
);
const LoginPage = Loader(lazy(() => import('../pages/LoginPage')));
const ErrorPage = Loader(lazy(() => import('../pages/ErrorPage')));;
const Dashboard =Loader(lazy(() => import('../pages/Dashboard')));
const UpdatePassword = Loader(lazy(() => import('../pages/UpdatePassword')));
const UpdateProfile = Loader(lazy(() => import('../pages/UpdateProfile')));
const CompanyList = Loader(lazy(() => import('../pages/Company/List')));
const CompanyCreateEdit = Loader(lazy(() => import('../pages/Company/CreateEdit')));
const CompanySliderList = Loader(lazy(() => import('../pages/CompanySlider/List')));
const CompanySliderCreateEdit = Loader(lazy(() => import('../pages/CompanySlider/CreateEdit')));
const CompanyClientList = Loader(lazy(() => import('../pages/CompanyClient/List')));
const CompanyClientCreateEdit = Loader(lazy(() => import('../pages/CompanyClient/CreateEdit')));
const CompanyServiceList = Loader(lazy(() => import('../pages/CompanyService/List')));
const CompanyServiceCreateEdit = Loader(lazy(() => import('../pages/CompanyService/CreateEdit')));
const CompanyFaqList = Loader(lazy(() => import('../pages/CompanyFaq/List')));
const CategoryCreateEdit =Loader(lazy(()=> import('../pages/category/CreateEdit')));
const CategoryList =Loader(lazy(()=> import('../pages/category/List')));
const TagCreateEdit =Loader(lazy(()=> import('../pages/Tags/CreateEdit')));
const TagList =Loader(lazy(()=> import('../pages/Tags/List')));
const UserCreateEdit =Loader(lazy(()=> import('../pages/Users/CreateEdit')));
const UserList =Loader(lazy(()=> import('../pages/Users/List')));
const BlogCreateEdit =Loader(lazy(()=> import('../pages/Blogs/CreateEdit')));
const BlogList =Loader(lazy(()=> import('../pages/Blogs/List')));
const PageCreateEdit =Loader(lazy(()=> import('../pages/PageManagement/CreateEdit')));
const PageList =Loader(lazy(()=> import('../pages/PageManagement/List')));
// function External() {
//     window.location.href = '/home.html';
//     return null;
//   }

const routes = (isLoggedIn) => [
    {
        path: '/',
        element:isLoggedIn?<Navigate to="/admin/dashboard" />:<Navigate to="/login" />,

    },
    {
        path: '/404',
        element: <ErrorPage />,

    },
    {
        path: '/login',
        element: <LoginPage />,

    },
    
 
    {
        path: '/admin',
        element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" />,
        children: [
            { element: <Navigate to="/admin/dashboard" replace /> },
            {
                path:"dashboard",
                element: <Dashboard />,
            },
           
            {
                path:"update-password",
                element: <UpdatePassword/>,
            },
            {
                path:"update-profile",
                element: <UpdateProfile/>,
            },
            {
                path:"company",
                element: <CompanyCreateEdit/>,
            },
            {
                path:"company-list",
                element: <CompanyList/>,
            },
            {
                path:"company-banner",
                element: <CompanySliderCreateEdit/>,
            },
            {
                path:"company-banner-list",
                element: <CompanySliderList/>,
            },
            {
                path:"company-client",
                element: <CompanyClientCreateEdit/>,
            },
            {
                path:"company-client-list",
                element: <CompanyClientList/>,
            },
            {
                path:"company-service",
                element: <CompanyServiceCreateEdit/>,
            },
            {
                path:"company-service-list",
                element: <CompanyServiceList/>,
            },
            {
                path:"company-faq-list",
                element:<CompanyFaqList/>
            },
            {
                path:"categories",
                element: <CategoryList/>,
            },
            {
                path:"create-category",
                element: <CategoryCreateEdit/>,
            },
            {
                path:"tags",
                element: <TagList/>,
            },
            {
                path:"create-tag",
                element: <TagCreateEdit/>,
            },
            {
                path:"Users",
                element: <UserList/>,
            },
            {
                path:"create-user",
                element: <UserCreateEdit/>,
            },
            {
                path:"articles",
                element: <BlogList/>,
            },
            {
                path:"create-article",
                element: <BlogCreateEdit/>,
            },
            {
                path:"pages",
                element: <PageList/>,
            },
            {
                path:"create-page",
                element: <PageCreateEdit/>,
            }
           

        ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
];

export default routes;